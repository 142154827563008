import React, { memo, useRef, useState } from 'react'
import { NewsItem } from '@types'
import { ResourcesWideCard } from '@components'
import { renderFullData } from '@utils'

import styles from './coverage.module.scss'

const RenderPurpleColumn = props => {
  const { sourceTitle, news } = props
  const [isDetailActive, setDetailActive] = useState(false)
  const [detail, setDetail] = useState(false)
  const ref = useRef(null)
  const onReadClick = summary => {
    setDetailActive(!isDetailActive)
    setDetail(summary)
    ref.current.scrollTo(0, 0)
  }

  return (
    <div
      ref={ref}
      key={sourceTitle}
      className={`${styles.coverageColumn} ${styles.coverageColumnPurple}`}
      data-source={sourceTitle}
    >
      {isDetailActive && (
        <div className={styles.closeDetail} onClick={() => setDetailActive(false)}>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 34C26.8366 34 34 26.8366 34 18C34 9.16344 26.8366 2 18 2C9.16344 2 2 9.16344 2 18C2 26.8366 9.16344 34 18 34Z"
              stroke="#FFC469"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="bevel"
            />
            <path
              d="M23.6575 23.6575L12.3438 12.3438"
              stroke="#FAF9F9"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="bevel"
            />
            <path
              d="M23.6562 12.3438L12.3425 23.6575"
              stroke="#FAF9F9"
              strokeWidth="3"
              strokeLinecap="round"
              strokeLinejoin="bevel"
            />
          </svg>
        </div>
      )}
      <div className={styles.coverageColumnHeader}>
        <div className={styles.coverageColumnHeaderText}>
          {sourceTitle} <span>+{news?.length}</span>
        </div>
        <div className={styles.coverageColumnHeaderLine} />
      </div>
      <div className={styles.coverageScrollable} style={{ overflowY: 'scroll' }}>
        {news?.map((news: NewsItem, index: number) => {
          const { id, title, image, link, updatedAt, sourceTitle, summary, date, insertedAt } = news
          const templateNum = ((index + 7) % 6).toString()
          const resultDate = renderFullData(date, updatedAt, insertedAt)

          return (
            <ResourcesWideCard
              key={id}
              summary={summary}
              template={templateNum}
              title={title}
              description={''}
              image={image}
              date={resultDate}
              publisher={sourceTitle}
              link={link}
              passedClick={() => onReadClick(summary)}
            />
          )
        })}
      </div>
      {isDetailActive && (
        <div
          className={`${styles.coverageDetail} ${styles.coverageDetailWide}`}
          dangerouslySetInnerHTML={{ __html: detail }}
        ></div>
      )}
    </div>
  )
}

export default memo(RenderPurpleColumn)
